export { default as BmwThreeDotsSvg } from './bmw-three-dots.svg';
export { default as BmwThreeStrikesSvg } from './bmw-three-strikes.svg';
export { default as CarReviewBadgeSvg } from './car-review-badge.svg';
export { default as LogoSvg } from './logo.svg';
export { default as HandLikeSvg } from './hand-like.svg';
export { default as ArrowRightSpecialSvg } from './arrow-right.svg';
export { default as ChatMsgSvg } from './chat-msg.svg';
export { default as ShareIconSvg } from './share-icon.svg';
export { default as PointyEyeSvg } from './pointy-eye.svg';
export { default as CloseIconSvg } from './close-icon.svg';
export { default as HandLikeOutlineSvg } from './hand-like-outline.svg';
export { default as LightBoxIconFillSvg } from './light-box-icon-fill.svg';
export { default as LightBoxIconSvg } from './light-box-icon.svg';
